.powered {
  color: #8a8b8c;
  line-height: 1.2rem;

  a {
    color: #0045c8;
    text-decoration: none;
  }
}

.version {
  color: #ffffff;
  font-size: 14px;
}
